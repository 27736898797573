import { useEffect, useState } from "react"
import { Toast } from "react-hot-toast"
import { useLocation, useParams } from "react-router-dom"
import { ByggsokEventType } from "../utils/ByggsokEventType"


export const useErrorToasts = () => {

    const [errorToasts, setErrorToasts] = useState<Toast[]>([])
    const [dismissedToasts, setDismissedToasts] = useState<string[]>([])
    // console.log('useErrorToasts', errorToasts)
    // console.log('useErrorToasts', dismissedToasts)
    // useEffect(() => {
    //     document.addEventListener('popstate', () => {
    //         console.log(window.location.pathname)
    //     })


    // }, [])
    useEffect(() => {
        const errors = errorToasts?.filter(x => x.message.toString().includes('Failed to fetch'))
        const dismissedErrors = dismissedToasts?.filter(x => x.includes('Failed to fetch'))
        if (errors.length + dismissedErrors.length >= 2) {
            var e = new CustomEvent("byggsokEvent", {
                detail: ByggsokEventType.NETWORK_ERROR
            });
            document.dispatchEvent(e);
        }
    }, [errorToasts, dismissedToasts])
    useEffect(() => {
        if (dismissedToasts.length > 20) {
            setDismissedToasts(dismissedToasts.slice(0, 10))
        }
    }, [dismissedToasts])
    const addErrorToast = (message: Toast) => {
        const messageStart = message.message.toString().substring(0, 50)
        const exist = errorToasts.find(t => t.id === message.id || t.message.toString().substring(0, 50) === messageStart)
        if (!exist && !dismissedToasts.includes(messageStart)) {
            setErrorToasts([...errorToasts, message])
            // if(message.duration){
            //     setTimeout(() => {
            //         removeErrorToast(message.id)
            //     }, message.duration)
            // }
        }
    }
    const removeErrorToast = (id: string) => {
        const toDelete = errorToasts.find(t => t.id === id)
        setErrorToasts(errorToasts.filter(t => t.id !== id))
        if (toDelete) {
            setDismissedToasts([...dismissedToasts, toDelete.message.toString()])
        }

    }
    const clearToasts = () => {
        setDismissedToasts([]) //setDismissedToasts([...dismissedToasts, ...errorToasts.map(t => t.message.toString())])
        setErrorToasts([])
    }
    return {
        errorToasts,
        addErrorToast,
        removeErrorToast,
        clearToasts
    }
}